<template>
  <octo-table
    enable-export
    enable-export-email
    show-filters
    :action="endpoints.DATATABLES.registries"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:personal_data="data">
      <div class="text-uppercase text-truncate">
        {{data.row | optional('surname')}} {{data.row | optional('name')}}
      </div>
    </template>

    <template v-slot:email="data">
      <div class="text-truncate ">
        {{data.row.defaultEmail ? data.row.defaultEmail.email : 'N.D.'}}
      </div>
    </template>

    <template v-slot:phone="data">
      <div class="text-uppercase text-truncate ">
        {{data.row.defaultPhone ? data.row.defaultPhone.phone : 'N.D.'}}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip content="Settings"
                    :open-delay="300"
                    placement="top">
          <base-button
            class="mx-1"
            size="sm"
            link
            icon
            @click="$router.push({name: 'registries.show', params: {id : data.row.id }})"
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>
    </template>
    <template slot="custom-filters">
      <div class="col-12 col-md-3">
        <base-input
          :label="$t('datatable.phone')"
          v-model="filters.phone"
        />
      </div>
      <div class="col-12 col-md-4">
        <base-input
          :label="$t('datatable.email')"
          v-model="filters.email"
        />
      </div>
      <div class="col-12 col-md-5">
        <base-input :label="$t('datatable.provinces')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.provinces"
          >
            <el-option
              v-for="option in provincesOptions"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import {Option, Select} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "RegistryDatatable",
  components: {Modal, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      name: "registryDatatable",
      showModal: false,
      endpoints: endpoints,
      filters: {
        provinces: [],
        email: '',
        phone: ''
      },
      fields: [
        {prop: 'personal_data', label: 'personal_data', minWidth: 200, slot: true},
        {prop: 'email', label: 'email', slot: true, align:'center'},
        {prop: 'phone', label: 'phone', slot: true, align:'center'},
        {prop: 'actions', label: 'actions',  slot: true},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      provinces: 'common/provinces'
    }),

    provincesOptions() {
      return this.$_.map(this.provinces, province => {
        return {
          label: province.sovracomunale,
          value: province.sigla_automobilistica,
        }
      });
    },
  },
}
</script>

<style scoped>

</style>
