<template>
  <div class="row">
    <div class="col-md-12">
      <registry-datatable ref="registryDatatable"/>
    </div>
  </div>
</template>

  <script>
  import RegistryDatatable from "@/pages/Registries/datatables/RegistryDatatable";

  export default {
    name: "IndexPage",
    components: {RegistryDatatable},
  }
</script>

<style scoped>

</style>
